import { CircularProgress, Grid, TableCell, TableRow } from "@mui/material"
import { Colors } from "../../../../app/colors"
import { Navigation } from "../../../../app/utils/components"
import { IconField } from "../../../../app/utils/components/dataTable/tableDisplay"
import { PlayerCount, RiddleCount, UsageChart } from "../utils"
import { DataTable, HeadCell } from "../../../../app/utils/components/dataTable/dataTable"

import { RiddleListData } from "../../../../app/data"
import { useContext, useState } from "react"
import { useGetAdminStatisticsQuery, useGetUserStatisticsQuery, UserStatistic } from "../../../api/cityriddlerApi"
import { extractText } from "../../../../app/utils/components/text/internationalTextService"
import { AuthContext } from "../../../../firebase/authProvider"
import { mapRiddleToForm } from "../../../riddleForm/riddleMapper"
import { useNavigate } from "react-router-dom"

let voidConst: void

export const MobileDashboard = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { data: userData, isLoading: userIsLoading } = useGetUserStatisticsQuery(
    { userId: user?.uid! },
    { skip: !user || user!.admin },
  )
  const { data: adminData, isLoading: adminIsLoading } = useGetAdminStatisticsQuery(voidConst, {
    skip: !user || !user!.admin,
  })

  const getData = (): UserStatistic | undefined => {
    if (user) {
      // if (user.admin) {
      //   return adminData
      // } else {
      return userData
      // }
    }
  }

  const headCells: HeadCell<RiddleListData>[] = [
    {
      id: "id",
      numeric: false,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Rätselname",
    },
    {
      id: "city",
      numeric: false,
      disablePadding: true,
      label: "Stadt",
    },
    {
      id: "usage",
      numeric: true,
      disablePadding: true,
      label: "Spieler",
    },
    {
      id: "status",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
  ]

  const rowRenderer = (
    row: RiddleListData,
    index: number,
    icons?: IconField[],
    iconRenderer?: (icons: IconField[], rowId: string) => JSX.Element[],
  ): JSX.Element => {
    const labelId = `enhanced-table-checkbox-${index}`
    //TODO change this behavior from "inactive" to "not public"
    const textColor = row.status ? Colors.Black : Colors.Black
    return (
      <TableRow key={row.id} tabIndex={-1}>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.id}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.name}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.city}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.usage}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.status ? "Publik" : "Nicht Publik"}
        </TableCell>
        {icons && iconRenderer ? iconRenderer(icons, row.id) : null}
      </TableRow>
    )
  }

  const tableData: RiddleListData[] =
    getData()
      ?.riddles.map((riddle) => {
        const riddleId = riddle.id!.toString()
        const statistics = getData()!.riddleStatistics[riddleId]
        return {
          id: riddleId,
          name: extractText(riddle.title).text,
          city: riddle.riddleMetadata.location.address?.city ?? "",
          usage: statistics ? statistics.totalUsage : 0,
          status: riddle.metadata.active ? 1 : 0,
          delete: "delete",
          edit: "edit",
        } as RiddleListData
      })
      .sort((r1, r2) => r2.usage - r1.usage) ?? []

  const playerCount = tableData
    .map((element) => element.usage)
    .reduce((accumulator, currentValue) => accumulator + currentValue, 0)

  const dailyRiddleUsages = Object.entries(getData()?.totalDailyRiddleUsage ?? []).map(([key, value]) => ({
    label: key,
    data: value,
  }))

  const extractRiddle = (event: any, edit: boolean) => {
    let idText = event.target.id
    if (!idText) {
      idText = event.target.ownerSVGElement.id
    }
    let id = idText.split(" ")[1]
    if (getData()) {
      const riddle = {
        ...(getData()?.riddles ?? []).filter((element) => {
          return element.id!.toString() === id
        })[0],
      }
      return mapRiddleToForm(riddle, edit)
    }
  }

  const editElement = (event: any) => {
    const riddle = extractRiddle(event, true)
    if (riddle)
      navigate(`/riddleForm`, {
        state: riddle,
      })
  }

  return (
    <Grid
      container
      maxWidth="95%"
      spacing={2}
      sx={{ margin: "auto" }}
      display={{
        xs: "flex",
        md: "none",
      }}>
      {(userIsLoading || adminIsLoading) && <CircularProgress />}
      <Navigation pageName="dashboard" />
      {getData() && (
        <>
          <Grid item xs={12}>
            <UsageChart usageData={dailyRiddleUsages} />
          </Grid>

          <Grid item xs={6}>
            <RiddleCount count={getData()?.riddles.length ?? 0} />
          </Grid>
          <Grid item xs={6}>
            <PlayerCount count={playerCount} />
          </Grid>

          <Grid item xs={12}>
            <Grid item xs={12}>
              <DataTable
                copy={false}
                edit={false}
                delete={false}
                search={false}
                add={false}
                data={tableData ?? []}
                headCells={headCells}
                deleteHelpers={{
                  deleteConfirmMessage: "Are you sure you want to delete this riddle?",
                  deleteFromStore: "",
                  deleteFromDatabase: () => {},
                }}
                navigationHelpers={{
                  editElement: editElement,
                  current: "riddles",
                  destination: "riddleForm",
                }}
                tableHelpers={{
                  comparatorField: "usage",
                  rowRenderer: rowRenderer,
                }}
                paging={{
                  totalCount: tableData?.length ?? 0,
                  currentPage: page,
                  currentPageSize: pageSize,
                  setCurrentPage: setPage,
                  setCurrentPageSize: setPageSize,
                }}
              />
            </Grid>
          </Grid>
        </>
      )}
    </Grid>
  )
}
