import { Grid } from "@mui/material"
import { PlayerCount, RiddleCount, UsageChart } from "../../../../utils"
import { DailyUsage } from "../../../../../../../app/data"
import React from "react"

interface GeneralInfoProperties {
  riddleCount: number
  playerCount: number
  dailyRiddleUsages: DailyUsage[]
}

export const GeneralInfo = (props: GeneralInfoProperties) => {
  const { riddleCount, playerCount, dailyRiddleUsages } = props

  return (
    <Grid container spacing={4}>
      <Grid item xs={3}>
        {riddleCount > 0 ? <RiddleCount count={riddleCount} /> : <></>}
      </Grid>
      <Grid item xs={3}>
        {playerCount > 0 ? <PlayerCount count={playerCount} /> : <></>}
      </Grid>
      <Grid item xs={6}>
        <UsageChart usageData={dailyRiddleUsages} />
      </Grid>
    </Grid>
  )
}
