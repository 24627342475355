import {
  DisplayedRiddleData,
  ExperienceFormState,
  IntroImageWithOrderIndex,
  IntroTextWithOrderIndex,
  IntroWithOrderIndex,
} from "../../app/data"
import { auth } from "../auth/firebase/firebaseSetup"
import * as api from "../api/cityriddlerApi"
import { Address, Experience, MinExperience, Riddle } from "../api/cityriddlerApi"
import { getTimeStamp } from "../../app/service/metadataService"
import { extractText } from "../../app/utils/components/text/internationalTextService"

const groupByOrder = function groupBy(collection: IntroWithOrderIndex[]) {
  return collection.reduce((previous: Map<number, IntroWithOrderIndex[]>, current: IntroWithOrderIndex) => {
    const key = current.orderIndex
    console.log(previous)
    if (!previous) {
      previous = new Map<number, IntroWithOrderIndex[]>()
    }
    console.log(previous)
    console.log(key)

    if (previous && !previous.has(key)) {
      previous.set(key, [] as IntroWithOrderIndex[])
    }

    const existing = previous.get(key)!
    existing.push(current)
    previous.set(key, existing)

    return previous
  }, new Map<number, IntroWithOrderIndex[]>())
}

function mapIntros(
  introHeaders: IntroTextWithOrderIndex[],
  introImages: IntroImageWithOrderIndex[],
  introTexts: IntroTextWithOrderIndex[],
) {
  const images = groupByOrder(introImages)
  const headers = groupByOrder(introHeaders)
  const texts = groupByOrder(introTexts)
  const intros = [] as api.IntroPage[]

  console.log(headers)
  console.log(texts)
  console.log(images)

  if (headers.size > 0)
    headers.forEach((header, orderIndex) => {
      const introText = texts.get(orderIndex)!.map((i) => i as api.InternationalText)
      const introImage = images.get(orderIndex)!.map((i) => i as api.Image)[0]
      const introHeader = header.map((i) => i as api.InternationalText)

      const intro = {
        id: undefined,
        orderIndex: orderIndex,
        image: introImage,
        introHeader: introHeader,
        introText: introText,
      } as api.IntroPage

      intros.push(intro)
    })
  console.log(intros)
  return intros
}

export function mapFormToApi(
  formState: ExperienceFormState,
  overviewImageUrl: string | null,
  finishImageUrl: string | null,
  coverImageUrl: string | null,
): api.Experience {
  console.log("mapFormToApi")
  const {
    id,
    steps,
    joinCode,
    needsPayment,
    event,
    introHeaders,
    introImages,
    introTexts,
    introVideoUrl,
    outroVideoUrl,
    displayName,
    descriptionShort,
    descriptionLong,
    tourId,
    maxJoins,
    usePoints,
    forceOffline,
  } = formState

  const intros = mapIntros(introHeaders, introImages, introTexts)
  const start = steps.find((s) => s.stepIndex === 0)!.riddle.riddleMetadata.location
  const user = auth.currentUser!

  let coverImage
  if (coverImageUrl) {
    coverImage = {
      url: coverImageUrl,
    }
  }
  let overviewImage
  if (overviewImageUrl) {
    overviewImage = {
      url: overviewImageUrl,
    }
  }
  let finishImage
  if (finishImageUrl) {
    finishImage = {
      url: finishImageUrl,
    }
  }

  const info = {
    displayName: displayName,
    descriptionShort: descriptionShort,
    descriptionLong: descriptionLong,
    coverImage: coverImage,
  } as api.ExperienceInfo

  const metadata = {
    maxJoins: maxJoins,
    tourId: tourId,
    timeStamp: getTimeStamp(),
    confirmCode: joinCode?.toLowerCase(),
  } as api.ExperienceMetadata

  const featureFlags = {
    usePoints: usePoints,
    overviewImage: overviewImage,
    finishImage: finishImage,
    needsPayment: needsPayment,
    event: event,
    intro: intros,
    introVideoUrl: introVideoUrl,
    outroVideoUrl: outroVideoUrl,
    forceOffline: forceOffline,
  } as api.FeatureFlags

  return {
    id: id,
    steps: steps,
    start: start,
    userId: user.uid,
    joinCode: joinCode.toLowerCase(),
    infos: info,
    metadata: metadata,
    featureFlags: featureFlags,
  } as api.Experience
}

export function mapExperienceToForm(experience: Experience, edit: boolean): ExperienceFormState {
  let introHeaders = extractIntroHeaders(experience)
  let introImages = extractIntroImages(experience)
  let introTexts = extractIntroTexts(experience)

  return {
    id: experience.id,
    steps: experience.steps,
    joinCode: experience.joinCode.toLowerCase(),
    needsPayment: experience.featureFlags.needsPayment,
    event: experience.featureFlags.event,
    introHeaders: introHeaders,
    introImages: introImages,
    introTexts: introTexts,
    introVideoUrl: experience.featureFlags.introVideoUrl,
    outroVideoUrl: experience.featureFlags.outroVideoUrl,
    displayName: experience.infos?.displayName,
    descriptionShort: experience.infos?.descriptionShort,
    descriptionLong: experience.infos?.descriptionShort,
    tourId: experience.metadata.tourId,
    maxJoins: experience.metadata.maxJoins,
    usePoints: experience.featureFlags.usePoints,
    forceOffline: experience.featureFlags?.forceOffline,
    finishImage: experience.featureFlags?.finishImage,
    coverImage: experience.infos?.coverImage,
    overviewImage: experience.featureFlags?.overviewImage,
    overviewImageFile: null,
    finishImageFile: null,
    coverImageFile: null,
    isError: false,
    minimized: false,
    edit: edit,
    languages: experience.infos?.displayName.map((t) => t.language) ?? [],
  } as ExperienceFormState
}

function extractIntroHeaders(experience: MinExperience | Experience) {
  let introHeaders: IntroTextWithOrderIndex[] = []
  experience.featureFlags.intro.map((intro, orderIndex) => {
    const headers = [...intro.introHeader].map((header) => {
      return {
        ...header,
        orderIndex: orderIndex,
      } as IntroTextWithOrderIndex
    })
    introHeaders = [...introHeaders, ...headers]
  })
  return introHeaders
}

function extractIntroImages(experience: MinExperience | Experience): IntroImageWithOrderIndex[] {
  let introImageList: IntroImageWithOrderIndex[] = []
  experience.featureFlags.intro.map((intro, orderIndex) => {
    const introImage = {
      ...intro.image,
      orderIndex: orderIndex,
    } as IntroImageWithOrderIndex

    introImageList = [...introImageList, introImage]
  })
  return introImageList
}

function extractIntroTexts(experience: MinExperience | Experience) {
  let introTexts: IntroTextWithOrderIndex[] = []
  experience.featureFlags.intro.map((intro, orderIndex) => {
    const headers = [...intro.introText].map((text) => {
      return {
        ...text,
        orderIndex: orderIndex,
      } as IntroTextWithOrderIndex
    })
    introTexts = [...introTexts, ...headers]
  })
  return introTexts
}

export function mapMinExperienceToForm(experience: MinExperience, edit: boolean): ExperienceFormState {
  let introHeaders = extractIntroHeaders(experience)
  let introImages = extractIntroImages(experience)
  let introTexts = extractIntroTexts(experience)

  return {
    id: experience.id,
    steps: [],
    joinCode: experience.joinCode.toLowerCase(),
    needsPayment: experience.featureFlags.needsPayment,
    event: experience.featureFlags.event,
    introHeaders: introHeaders,
    introTexts: introTexts,
    introImages: introImages,
    introVideoUrl: experience.featureFlags.introVideoUrl,
    outroVideoUrl: experience.featureFlags.outroVideoUrl,
    displayName: experience.infos?.displayName,
    descriptionShort: experience.infos?.descriptionShort,
    descriptionLong: experience.infos?.descriptionShort,
    tourId: experience.metadata.tourId,
    maxJoins: experience.metadata.maxJoins,
    usePoints: experience.featureFlags.usePoints,
    forceOffline: experience.featureFlags?.forceOffline,
    finishImage: experience.featureFlags?.finishImage,
    coverImage: experience.infos?.coverImage,
    overviewImage: experience.featureFlags?.overviewImage,
    overviewImageFile: null,
    finishImageFile: null,
    coverImageFile: null,
    introImageFiles: null,
    isError: false,
    minimized: true,
    edit: edit,
    languages: experience.infos?.displayName.map((t) => t.language) ?? [],
  } as ExperienceFormState
}

export const getAddress = (address?: Address): string => {
  if (address) {
    return address.street + " " + address.streetNumber + ", " + address.zipCode + " " + address.city
  } else {
    return ""
  }
}

export const mapToDataField = (riddle: Riddle, index: number): DisplayedRiddleData => {
  return {
    id: riddle.id!.toString(),
    name: extractText(riddle.title).text,
    description: extractText(riddle.description).text,
    address: getAddress(riddle.riddleMetadata.location.address),
    category: riddle.riddleMetadata.categories?.[0]?.name,
    status: riddle.metadata.active,
    index: index,
    flagged: riddle.metadata.flagged,
    flagNote: riddle.metadata.flagNote,
    riddle: riddle,
  } as DisplayedRiddleData
}

export const mapListToDataField = (riddles: Riddle[]): DisplayedRiddleData[] => {
  return riddles.map((riddle, index) => {
    return mapToDataField(riddle, index)
  })
}

export const mapDataFieldListToRiddleList = (data: DisplayedRiddleData[]): Riddle[] => {
  return data.map((data, index) => {
    return data.riddle
  })
}
