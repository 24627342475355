import { Box, CircularProgress, Grid, Tab, Tabs, Typography } from "@mui/material"
import * as React from "react"
import { SyntheticEvent, useContext, useEffect, useMemo, useState } from "react"
import { SelectFormState, setFormState, setInitialState, setIsError, setSteps } from "./experienceFormSlice"
import { TourMap } from "./components/selectRiddles/tourMap"
import { SortableExperienceStep } from "../../app/data"
import { SortableRiddleList } from "./components/selectRiddles/sortableRiddleList"
import { RiddleSelect } from "./components/selectRiddles/riddleSelect"
import { a11yProps, CrFormTabPanel } from "../../app/utils/components/crFormTabPanel/crFormTabPanel"
import { CityRiddlerButton } from "../../app/themes/cityRiddlerButton"
import { useTranslation } from "react-i18next"
import { CityRiddlerFormLabel, CityRiddlerSubmitButton } from "../../app/themes"
import { ExperienceJoinCode } from "./components/experienceJoinCode"
import { ExperienceEvent } from "./components/experienceEvent"
import { ExperienceNeedsPayment } from "./components/experiencePayment"
import { ExperienceIntroVideo } from "./components/experienceIntroVideo"
import { ExperienceOutroVideo } from "./components/experienceOutroVideo"
import { LanguageOptions } from "./components/languageOptions"
import { ExperienceFormErrors, ExperienceFormText } from "../../i18"
import { ExperienceTextInput } from "./components/experienceText/experienceTextInput"
import { useAppDispatch, useAppSelector } from "../../app/hooks"
import { mapFormToApi, mapListToDataField } from "./experienceMapper"
import { useLocation, useNavigate } from "react-router-dom"
import {
  Experience,
  ExperienceStep,
  useCreateExperienceMutation,
  useGetOwnedRiddlesOfUserQuery,
  useUpdateExperienceMutation,
} from "../api/cityriddlerApi"
import { ExperienceUsePoints } from "./components/experiencePoints"
import { ExperienceForceOffline } from "./components/experienceOffline"
import { ExperienceFinishImage } from "./components/experienceFinishImage"
import { ExperienceOverviewImage } from "./components/experienceOverviewImage"
import { ExperienceCoverImage } from "./components/experienceCoverImage"
import { ExperienceTourId } from "./components/experienceTourId"
import { ExperienceMaxJoins } from "./components/experienceMaxJoins"
import { extractText } from "../../app/utils/components/text/internationalTextService"
import { getTimeStamp } from "../../app/service/metadataService"
import { uploadImage } from "../../app/utils/components/fileUpload"
import { Errors } from "../../app/utils/components/error/errors"
import { Colors } from "../../app/colors"
import { TooltipIcon } from "../../app/utils/components/info/infoIcon"
import { AuthContext } from "../../firebase/authProvider"

export const ExperienceForm = () => {
  const dispatch = useAppDispatch()
  const navigate = useNavigate()

  const [loading, setLoading] = useState<boolean>(false)
  const [isEdit, setIsEdit] = useState<boolean>(false)

  const { state } = useLocation()

  const [riddleDialogOpen, setRiddleDialogOpen] = React.useState(false)

  const [errors, setErrors] = useState<String[]>()

  const [selectedRiddles, setSelectedRiddles] = useState<SortableExperienceStep[]>([])
  const [formTabIndex, setFormTabIndex] = React.useState(0)
  const { t } = useTranslation()
  const formState = useAppSelector(SelectFormState)

  const [editExperience] = useUpdateExperienceMutation()
  const [postFormData] = useCreateExperienceMutation()

  const { user } = useContext(AuthContext)
  const { data: riddles, isLoading } = useGetOwnedRiddlesOfUserQuery({ userId: user?.uid! })

  useEffect(() => {
    const steps = selectedRiddles.map((selected) => {
      return {
        riddle: selected.riddle,
        stepIndex: selected.stepIndex,
        suggestions: [],
        partner: [],
        timeStamp: getTimeStamp(),
      } as ExperienceStep
    })
    console.log("dispatch steps")
    dispatch(setSteps(steps))
  }, [selectedRiddles, dispatch])

  const handleChange = (event: React.SyntheticEvent, newIndex: number) => {
    setFormTabIndex(newIndex)
  }

  useMemo(() => {
    if (state) {
      dispatch(setFormState(state))
      setIsEdit(state.edit)
      const steps: ExperienceStep[] = state.steps
      const selected = steps
        // .sort((s1, s2) => (s1.stepIndex > s2.stepIndex ? 1 : -1))
        .map((step: ExperienceStep) => {
          const riddle = step.riddle
          return {
            id: riddle.id!.toString(),
            name: extractText(riddle.title).text,
            riddle: riddle,
            stepIndex: step.stepIndex,
          } as SortableExperienceStep
        })
      console.log(selected)
      setSelectedRiddles(selected)
    }
  }, [state])

  async function handleUpload(imageFile: File | null) {
    let imageUrl: string | null = null
    if (imageFile) {
      const imageUpload = await uploadImage(imageFile!)
      if (imageUpload) {
        const { url } = imageUpload
        imageUrl = url
      }
    }
    return imageUrl
  }

  const handleSubmit = async (event: SyntheticEvent) => {
    console.log("submit")
    event.preventDefault()
    setLoading(true)
    const isError = validateFormData()
    console.log("error: " + isError)
    if (!isError) {
      let overviewImageUrl = await handleUpload(formState.overviewImageFile)
      let finishImageUrl = await handleUpload(formState.finishImageFile)
      let coverImageUrl = await handleUpload(formState.coverImageFile)
      let mappedData: Experience
      mappedData = mapFormToApi(formState, overviewImageUrl, finishImageUrl, coverImageUrl)

      if (isEdit) {
        await editExperience({
          experience: { ...mappedData, id: state.id },
          id: state.id,
        })
      } else {
        await postFormData({ experience: mappedData })
      }

      window.history.replaceState(undefined, "/experienceForm")
      dispatch(setInitialState())
      setLoading(false)
      navigate("/experiences")
    } else {
      setLoading(false)
    }
  }

  const validateFormData = () => {
    let isError = false
    let errors: String[] = []

    if (!formState.steps && formState.steps !== 0) {
      isError = true
      errors = [...errors, t(ExperienceFormErrors.steps)!]
    }
    if (!formState.joinCode) {
      errors = [...errors, t(ExperienceFormErrors.joinCode)!]
      isError = true
    }

    setErrors(errors)
    dispatch(setIsError(isError))
    return isError
  }
  const clickNext = () => {
    setFormTabIndex(formTabIndex + 1)
  }

  const handleClickOpen = () => {
    setRiddleDialogOpen(true)
  }

  const handleClose = (selectedRiddles: SortableExperienceStep[]) => {
    console.log("setSelectedRiddles")
    setRiddleDialogOpen(false)
    setSelectedRiddles(selectedRiddles)
  }

  const setItems = (sortedRiddles: SortableExperienceStep[]) => {
    console.log("sorted riddles changed")
    console.log(sortedRiddles)

    const mapped = sortedRiddles.map((step, index) => {
      step.stepIndex = index
      return step
    })
    setSelectedRiddles(mapped)
  }

  return (
    <Grid container component="form" spacing={6} onSubmit={handleSubmit} sx={{ marginTop: 0, marginBottom: 5 }}>
      <Box sx={{ width: "100%" }}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            TabIndicatorProps={{
              style: {
                height: 5,
                backgroundColor: Colors.SecondaryLight,
              },
            }}
            sx={{
              textColor: Colors.BackgroundLight,
              backgroundColor: Colors.PrimaryLight,
            }}
            value={formTabIndex}
            onChange={handleChange}
            aria-label="riddle form tabs"
            variant="fullWidth"
            indicatorColor="secondary">
            <Tab label="Allgemein" {...a11yProps(0)} />
            <Tab label="Bilder" {...a11yProps(1)} />
            <Tab label="Texte" {...a11yProps(2)} />
            <Tab label="Abenteuer Features" {...a11yProps(3)} />
            <Tab label="Rätsel" {...a11yProps(4)} />
          </Tabs>
        </Box>

        {/*Allgemein*/}
        <CrFormTabPanel value={formTabIndex} index={0}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={6}>
              <ExperienceJoinCode />
            </Grid>
            <Grid item xs={6}>
              <ExperienceMaxJoins />
            </Grid>
            <Grid item xs={12}>
              <ExperienceTourId />
            </Grid>
            <Grid item xs={12}>
              <div>
                <CityRiddlerButton variant="contained" onClick={clickNext}>
                  {t(ExperienceFormText.nextButton)}
                </CityRiddlerButton>
              </div>
              <div>
                <Typography variant="caption">{t(ExperienceFormText.requiredInfo)}</Typography>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>

        {/*Bilder*/}
        <CrFormTabPanel value={formTabIndex} index={1}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={6}>
              <ExperienceCoverImage />
            </Grid>
            <Grid item xs={6}>
              <ExperienceOverviewImage />
            </Grid>
            <Grid item xs={6}>
              <ExperienceFinishImage />
            </Grid>

            <Grid item xs={12}>
              <div>
                <CityRiddlerButton variant="contained" onClick={clickNext}>
                  {t(ExperienceFormText.nextButton)}
                </CityRiddlerButton>
              </div>
              <div>
                <Typography variant="caption">{t(ExperienceFormText.requiredInfo)}</Typography>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>

        {/*Texte*/}
        <CrFormTabPanel value={formTabIndex} index={2}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={12}>
              <LanguageOptions />
            </Grid>
            <Grid item container spacing={12}>
              <Grid item xs={12}>
                <ExperienceTextInput />
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <div>
                <CityRiddlerButton variant="contained" onClick={clickNext}>
                  {t(ExperienceFormText.nextButton)}
                </CityRiddlerButton>
              </div>
              <div>
                <Typography variant="caption">{t(ExperienceFormText.requiredInfo)}</Typography>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>

        {/*Features*/}
        <CrFormTabPanel index={formTabIndex} value={3}>
          <Grid container spacing={3} sx={{ marginTop: 0, marginBottom: 5 }}>
            <Grid item xs={6}>
              <ExperienceIntroVideo />
            </Grid>
            <Grid item xs={6}>
              <ExperienceOutroVideo />
            </Grid>
            <Grid item xs={3}>
              <ExperienceUsePoints />
            </Grid>
            <Grid item xs={3}>
              <ExperienceForceOffline />
            </Grid>
            <Grid item xs={3}>
              <ExperienceEvent />
            </Grid>
            <Grid item xs={3}>
              <ExperienceNeedsPayment />
            </Grid>
            <Grid item xs={12}>
              <div>
                <div>
                  <CityRiddlerButton variant="contained" onClick={clickNext}>
                    {t(ExperienceFormText.nextButton)}
                  </CityRiddlerButton>
                </div>
                <div>
                  <Typography variant="caption">{t(ExperienceFormText.requiredInfo)}</Typography>
                </div>
              </div>
            </Grid>
            <Grid item xs={12}></Grid>
          </Grid>
        </CrFormTabPanel>

        {/*Steps*/}
        <CrFormTabPanel value={formTabIndex} index={4}>
          <Grid container item xs={12} marginBottom={50} spacing={3}>
            <Grid item xs={6}>
              <Grid item xs={12}>
                <CityRiddlerFormLabel required={true} focused={false} sx={{ display: "flex", alignItems: "center" }}>
                  <Typography sx={{ display: "flex", alignItems: "center" }}>
                    <TooltipIcon text={t(ExperienceFormText.riddleSelectInfo)} />
                    {t(ExperienceFormText.riddleSelectLabel)}
                  </Typography>
                </CityRiddlerFormLabel>
              </Grid>
              <SortableRiddleList steps={selectedRiddles} setSteps={setItems} />
              <Grid item xs={12}>
                <RiddleSelect
                  loading={isLoading}
                  riddles={mapListToDataField(riddles ?? [])}
                  onClick={handleClickOpen}
                  open={riddleDialogOpen}
                  onClose={handleClose}
                  selectedRiddles={selectedRiddles}
                />
              </Grid>
            </Grid>
            <Grid item xs={6}>
              <TourMap
                stops={selectedRiddles
                  .sort((s1, s2) => (s1.stepIndex > s2.stepIndex ? 1 : -1))
                  .map((r) => r.riddle.riddleMetadata.location.gpsData)
                  .map(({ longitude, latitude }) => new google.maps.LatLng(latitude, longitude))}
              />
            </Grid>

            <Grid item xs={12}>
              <Errors errors={errors} />
            </Grid>

            <Grid item xs={12}>
              <div>
                <CityRiddlerSubmitButton type="submit" variant="contained" disabled={loading}>
                  {t(ExperienceFormText.submitButton)} {loading && <CircularProgress />}
                </CityRiddlerSubmitButton>
              </div>
              <div>
                <Typography variant="caption">{t(ExperienceFormText.requiredInfo)}</Typography>
              </div>
            </Grid>
          </Grid>
        </CrFormTabPanel>
      </Box>
    </Grid>
  )
}
