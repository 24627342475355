import { Grid, Typography } from "@mui/material"
import { NavLink } from "../../../../app/utils/components"
import HomeIcon from "@mui/icons-material/Home"
import AccountCircleIcon from "@mui/icons-material/AccountCircle"
import CreateIcon from "@mui/icons-material/Create"
import ListAltIcon from "@mui/icons-material/ListAlt"
import LocationOnIcon from "@mui/icons-material/LocationOn"
import { useContext } from "react"
import { AuthContext } from "../../../../firebase/authProvider"
import { Roles } from "../../../../app/data/userModels"

export interface NavigationMenuProps {
  pageName: string
}

export const NavigationMenu = (props: NavigationMenuProps) => {
  const { user } = useContext(AuthContext)
  /*
          <Grid item xs={12}>
            <NavLink
              label="Richtlinien"
              destination="dashboard"
              icon={LightbulbIcon}
            />
          </Grid>
          
      
        */

  return (
    <Grid item container spacing={2}>
      <Grid item xs={12}>
        <Typography variant="h4">{props.pageName}</Typography>
      </Grid>
      <Grid item xs={12}>
        <NavLink label="Home" destination="dashboard" icon={HomeIcon} />
      </Grid>
      <Grid item xs={12}>
        <NavLink label="Profil" destination="profile" icon={AccountCircleIcon} />
      </Grid>
      {user?.admin ||
        (user?.roles.includes(Roles.RIDDLE_CREATE) && (
          <Grid item xs={12}>
            <NavLink label="Neues Rätsel" destination="riddleForm" icon={CreateIcon} />
          </Grid>
        ))}
      <Grid item xs={12}>
        <NavLink label="Rätsel" destination="riddles" icon={ListAltIcon} />
      </Grid>
      {user?.admin ||
        (user?.roles.includes(Roles.EXPERIENCE_CREATE) && (
          <Grid item xs={12}>
            <NavLink label="Neues Abenteuer" destination="experienceForm" icon={CreateIcon} />
          </Grid>
        ))}
      {user?.admin ||
        (user?.roles.includes(Roles.EXPERIENCE_READ) && (
          <Grid item xs={12}>
            <NavLink label="Abenteuer" destination="experiences" icon={ListAltIcon} />
          </Grid>
        ))}
      {user?.admin ||
        (user?.roles.includes(Roles.RIDDLE_MAP) && (
          <Grid item xs={12}>
            <NavLink label="Rätsel Karte" destination="mapdisplay" icon={LocationOnIcon} />
          </Grid>
        ))}
    </Grid>
  )
}
