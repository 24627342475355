import { Card, CardContent, Grid } from "@mui/material"
import SearchIcon from "@mui/icons-material/Search"
import _ from "lodash"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { Colors } from "../../../colors"
import useDebounce from "../../../hooks/useDebounce"
import Input from "../input/input"
import { AddCircleIconButton } from "../addCircleIconButton/addCircleIconButton"
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline"
import EditOutlinedIcon from "@mui/icons-material/EditOutlined"
import ContentCopyOutlinedIcon from "@mui/icons-material/ContentCopyOutlined"
import { IconField, TableDisplay } from "./tableDisplay"

interface DataTableProperties<T extends DataFields> {
  data: T[]
  headCells: HeadCell<T>[]
  deleteHelpers?: DeleteHelpers
  copyElement?: (event: any) => void
  navigationHelpers: NavigationHelpers
  tableHelpers: TableHelpers<T>
  icons?: boolean
  paging?: TablePageInfos
  search: boolean
  delete: boolean
  copy: boolean
  edit: boolean
  add: boolean
}

export interface TablePageInfos {
  totalCount: number
  currentPage: number
  currentPageSize: number
  useSlicer?: boolean
  setCurrentPage: React.Dispatch<React.SetStateAction<number>>
  setCurrentPageSize: React.Dispatch<React.SetStateAction<number>>
}

export interface HeadCell<T> {
  id: keyof T
  numeric: boolean
  disablePadding: boolean
  label: string
}

interface DeleteHelpers {
  deleteConfirmMessage: string
  deleteFromStore: any
  deleteFromDatabase: any
}

interface NavigationHelpers {
  editElement: (event: any) => void
  current: string
  destination: string
}

interface TableHelpers<T extends DataFields> {
  comparatorField: keyof T
  rowRenderer: (row: T, index: number) => JSX.Element
}

export interface DataFields {
  id: string
  name: string
}

export const DataTable = <T extends DataFields>(props: DataTableProperties<T>) => {
  const { data, headCells, icons, paging, copyElement } = props
  const { deleteConfirmMessage, deleteFromStore, deleteFromDatabase } = props.deleteHelpers || {}
  const { current, destination, editElement } = props.navigationHelpers
  const { comparatorField, rowRenderer } = props.tableHelpers
  const navigate = useNavigate()
  const [displayedData, setDisplayedData] = useState<T[]>(data)
  const [searchText, setSearchText] = useState<string>("")
  const debouncedSearchText = useDebounce(searchText, 500)

  const onSearchTextChange = (event: any) => {
    setSearchText(event.target.value)
  }

  useEffect(() => {
    const handleSearch = () => {
      if (debouncedSearchText) {
        const filteredDisplayData = _.filter(data, function (element) {
          return element.name.indexOf(debouncedSearchText) !== -1
        })
        setDisplayedData(filteredDisplayData)
      } else {
        setDisplayedData(data)
      }
    }

    handleSearch()
  }, [debouncedSearchText])

  const deleteTableElement = async (event: any) => {
    if (window.confirm(deleteConfirmMessage)) {
      let idText = event.target.id
      if (!idText) {
        idText = event.target.ownerSVGElement.id
      }
      let id = idText.split(" ")[1]
      let updatedData = _.filter(data, function (o) {
        return o.id.toString() !== id
      })
      setDisplayedData(updatedData)
      await deleteFromDatabase(parseInt(id))
    }
  }
  const iconsList: IconField[] = []
  if (icons) {
    if (props.edit) {
      iconsList.push({
        label: "",
        icon: EditOutlinedIcon,
        iconColor: Colors.Primary,
        id: "edit",
        handleClick: editElement,
      })
    }
    if (props.delete) {
      iconsList.push({
        label: "",
        icon: DeleteOutlineIcon,
        iconColor: Colors.Secondary,
        id: "delete",
        handleClick: deleteTableElement,
      })
    }
    if (props.copy) {
      iconsList.push({
        label: "",
        icon: ContentCopyOutlinedIcon,
        iconColor: Colors.Secondary,
        id: "copy",
        handleClick: copyElement,
      })
    }
  }

  return (
    <Card elevation={3} sx={{ borderRadius: "20px", backgroundColor: Colors.BackgroundLight }}>
      <CardContent
        sx={{
          alignItems: "center",
        }}>
        <Grid container>
          {props.search && (
            <Grid container item xs={9}>
              <Input
                name="searchBar"
                label=""
                type="text"
                handleChange={onSearchTextChange}
                required={false}
                icon={SearchIcon}
                iconColor={Colors.PrimaryDark}
                handleClickIcon={onSearchTextChange}
                borderRadius="20px"
              />
            </Grid>
          )}
          <Grid item xs={12} sx={{ backgroundColor: Colors.BackgroundLight }}>
            <TableDisplay
              data={displayedData}
              headCells={headCells}
              comparatorField={comparatorField}
              rowRenderer={rowRenderer}
              paging={paging}
              icons={iconsList}
            />
          </Grid>
          {props.add && (
            <Grid item container xs={3}>
              <AddCircleIconButton destination={destination} fromPage={current} />
            </Grid>
          )}
        </Grid>
      </CardContent>
    </Card>
  )
}
