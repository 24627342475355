import { CircularProgress, Grid, TableCell, TableRow } from "@mui/material"
import { Colors } from "../../app/colors"
import { Footer, Navigation } from "../../app/utils/components"
import { IconField } from "../../app/utils/components/dataTable/tableDisplay"
import { DataFields, DataTable, HeadCell } from "../../app/utils/components/dataTable/dataTable"
import { useNavigate } from "react-router-dom"
import { Address, Riddle, useDeleteRiddleMutation, useGetOwnedRiddlesOfUserQuery } from "../api/cityriddlerApi"
import { extractText } from "../../app/utils/components/text/internationalTextService"
import { mapRiddleToForm } from "../riddleForm/riddleMapper"
import React, { useContext, useEffect, useState } from "react"
import { AuthContext } from "../../firebase/authProvider"
import { Roles } from "../../app/data/userModels"

function getAddress(address: Address) {
  if (!address) return "unknown"
  return address.street + " " + address.streetNumber + ", " + address.zipCode + " " + address.city
}

export const Riddles = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)

  const { data, isLoading } = useGetOwnedRiddlesOfUserQuery({ userId: user?.uid! })
  const [deleteRiddle] = useDeleteRiddleMutation()
  const deleteConfirmMessage = "Are you sure you want to delete this riddle?"
  const [filteredData, setFilteredData] = useState<Riddle[]>(data ?? [])

  type RiddleDataField = Riddle & DataFields & Indexed

  useEffect(() => {
    if (data) setFilteredData(data)
  }, [data])

  interface Indexed {
    index: number
  }

  const headCells: HeadCell<RiddleDataField>[] = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "riddleMetadata",
      numeric: false,
      disablePadding: true,
      label: "PLZ",
    },
    {
      id: "riddleMetadata",
      numeric: false,
      disablePadding: true,
      label: "Addresse",
    },
    {
      id: "name",
      numeric: false,
      disablePadding: true,
      label: "Rätselname",
    },
    {
      id: "description",
      numeric: false,
      disablePadding: true,
      label: "Rätsel",
    },
    {
      id: "metadata",
      numeric: false,
      disablePadding: true,
      label: "Status",
    },
  ]

  const rowRenderer = (
    row: RiddleDataField,
    index: number,
    icons?: IconField[],
    iconRenderer?: (icons: IconField[], rowId: string) => JSX.Element[],
  ): JSX.Element => {
    // const textColor = row.metadata.active ? (row.metadata.flagged ? Colors.Red : Colors.Black) : Colors.Grey
    const textColor = Colors.Black
    return (
      <TableRow key={row.id} tabIndex={-1}>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.id}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.riddleMetadata.location.address?.zipCode}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {getAddress(row.riddleMetadata.location.address!)}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.name}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {extractText(row.description).text}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
            color: textColor,
          }}>
          {row.metadata.active ? "Aktiv" : "Inaktiv"}
        </TableCell>
        {icons && iconRenderer ? iconRenderer(icons, row.id) : null}
      </TableRow>
    )
  }

  const extractRiddle = (event: any, edit: boolean) => {
    let idText = event.target.id
    if (!idText) {
      idText = event.target.ownerSVGElement.id
    }
    let id = idText.split(" ")[1]
    if (data) {
      const riddle = {
        ...(data ?? []).filter((element) => {
          return element.id!.toString() === id
        })[0],
      }
      return mapRiddleToForm(riddle, edit)
    }
  }

  const editElement = (event: any) => {
    const riddle = extractRiddle(event, true)
    if (riddle)
      navigate(`/riddleForm`, {
        state: riddle,
      })
  }

  const copyElement = (event: any) => {
    const riddle = extractRiddle(event, false)
    if (riddle) riddle.id = null
    navigate(`/riddleForm`, {
      state: riddle,
    })
  }

  const mapToDataField = (riddles: Riddle[]): RiddleDataField[] => {
    return riddles.map((riddle, idx) => {
      let tempData = { ...riddle } as RiddleDataField
      tempData.name = extractText(riddle.title).text
      tempData.index = idx
      return tempData
    })
  }

  // const handleFilterChange = (filtered: Riddle[]) => {
  //   setFilteredData(filtered)
  // }

  return (
    <>
      <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }}>
        <Navigation pageName="Rätsel" />
        <Grid container item md={10} xs={12} marginBottom={50}>
          {/*<Grid item xs={12}>*/}
          {/*  <RiddleFilter riddles={data} handleFilterChange={handleFilterChange} />*/}
          {/*</Grid>*/}
          <Grid item xs={12} direction="column" alignItems="center" justifyContent="center">
            {isLoading && <CircularProgress />}
            {data && (
              <DataTable
                search={true}
                edit={user?.admin || user?.roles.includes(Roles.RIDDLE_EDIT) || false}
                delete={user?.admin || user?.roles.includes(Roles.RIDDLE_DELETE) || false}
                copy={user?.admin || user?.roles.includes(Roles.RIDDLE_COPY) || false}
                add={user?.admin || user?.roles.includes(Roles.RIDDLE_CREATE) || false}
                data={mapToDataField(data ?? [])}
                headCells={headCells}
                copyElement={copyElement}
                deleteHelpers={{
                  deleteConfirmMessage: deleteConfirmMessage,
                  deleteFromStore: "",
                  deleteFromDatabase: deleteRiddle,
                }}
                navigationHelpers={{
                  editElement: editElement,
                  current: "riddles",
                  destination: "riddleForm",
                }}
                tableHelpers={{
                  comparatorField: "id",
                  rowRenderer: rowRenderer,
                }}
                icons={true}
              />
            )}
          </Grid>
        </Grid>
      </Grid>
      <Footer />
    </>
  )
}
