import { CircularProgress, Grid, TableCell, TableRow } from "@mui/material"
import { useNavigate } from "react-router-dom"
import { DataFields, DataTable, HeadCell } from "../../app/utils/components/dataTable/dataTable"
import { IconField } from "../../app/utils/components/dataTable/tableDisplay"
import { Footer, Navigation } from "../../app/utils/components"
import {
  Address,
  FeatureFlags,
  MinExperience,
  useGetExperienceByIdQuery,
  useGetOwnedExperiencesOfUserQuery,
} from "../api/cityriddlerApi"
import React, { useContext, useEffect, useState } from "react"
import { mapExperienceToForm } from "../experienceForm/experienceMapper"
import { CrLoadingOverlay } from "../../app/utils/components/overlay/crLoadingOverlay"
import { ExperienceByCodeForm } from "./experienceByCodeForm"
import { AuthContext } from "../../firebase/authProvider"
import { Roles } from "../../app/data/userModels"

function getAddress(address: Address) {
  return address.street + " " + address.streetNumber + ", " + address.zipCode + " " + address.city
}

export const Experiences = () => {
  const navigate = useNavigate()
  const { user } = useContext(AuthContext)
  const [editMode, setEditMode] = useState(false)
  const [copyMode, setCopyMode] = useState(false)

  const [page, setPage] = useState(0)
  const [pageSize, setPageSize] = useState(10)
  const { data, isLoading, isFetching } = useGetOwnedExperiencesOfUserQuery({
    userId: user?.uid!,
  })

  // const loadedPages = new Set<number>([0])

  // const prefetchPage = usePrefetch("getAllExperiences")

  // const prefetchNext = useCallback(
  //   (page: number) => {
  //     prefetchPage({
  //       page: page,
  //       size: pageSize,
  //     })
  //   },
  //   [prefetchPage, page],
  // )

  const [expId, setExpId] = useState<number>()
  const [loadExp, setLoadExp] = useState(false)
  const { data: experience, isLoading: experienceLoading } = useGetExperienceByIdQuery(
    { experienceId: expId! },
    { skip: !loadExp },
  )

  type ExperienceDataField = MinExperience & FeatureFlags & DataFields

  const headCells: HeadCell<ExperienceDataField>[] = [
    {
      id: "id",
      numeric: true,
      disablePadding: true,
      label: "ID",
    },
    {
      id: "start",
      numeric: false,
      disablePadding: true,
      label: "Addresse",
    },
    {
      id: "joinCode",
      numeric: false,
      disablePadding: true,
      label: "Join Code",
    },
    {
      id: "metadata",
      numeric: true,
      disablePadding: true,
      label: "Join Counter",
    },
    {
      id: "needsPayment",
      numeric: true,
      disablePadding: true,
      label: "€€",
    },
    {
      id: "event",
      numeric: true,
      disablePadding: true,
      label: "Event",
    },
  ]

  const rowRenderer = (
    row: ExperienceDataField,
    index: number,
    icons?: IconField[],
    iconRenderer?: (icons: IconField[], rowId: string) => JSX.Element[],
  ): JSX.Element => {
    return (
      <TableRow key={row.id} tabIndex={-1}>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {row.id}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {getAddress(row.start.address!)}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {row.joinCode}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {row.metadata.joinCount}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {row.featureFlags.needsPayment ? "Ja" : "Nein"}
        </TableCell>
        <TableCell
          align={"left"}
          sx={{
            fontSize: 17,
            borderBottom: "none",
          }}>
          {row.featureFlags.event ? "Ja" : "Nein"}
        </TableCell>
        {icons && iconRenderer ? iconRenderer(icons, row.id) : null}
      </TableRow>
    )
  }

  useEffect(() => {
    if (experience) {
      console.log(experience)

      const mapped = mapExperienceToForm(experience, editMode)

      if (copyMode) {
        mapped.id = null
      }
      setEditMode(false)
      setCopyMode(false)

      navigate(`/experienceForm`, {
        state: mapped,
      })
    }
  }, [experience])

  const loadExperience = (event: any) => {
    let idText = event.target.id
    if (!idText) {
      idText = event.target.ownerSVGElement.id
    }
    let id = idText.split(" ")[1]
    if (data) {
      setExpId(id)
      setLoadExp(true)
    }
  }

  const editElement = async (event: any) => {
    setEditMode(true)
    setCopyMode(false)
    loadExperience(event)
  }

  const copyElement = async (event: any) => {
    setEditMode(false)
    setCopyMode(true)
    loadExperience(event)
  }

  // useEffect(() => {
  //   if (page !== data?.totalPages) {
  //     prefetchNext(page + 1)
  //   }
  //   if (page - 1 > 0) {
  //     prefetchNext(page - 1)
  //   }
  //   if (data && data.totalPages && !loadedPages.has(data.totalPages)) {
  //     prefetchNext(data.totalPages)
  //   }
  // }, [data, page, prefetchNext])

  const mapToDataField = (experiences: MinExperience[]): ExperienceDataField[] => {
    console.log(experiences)
    return experiences.map((exp) => {
      let tempData = { ...exp } as ExperienceDataField
      tempData.name = exp.joinCode
      return tempData
    })
  }

  return (
    <>
      <CrLoadingOverlay active={loadExp} label="Lade Abenteuer">
        <Grid container maxWidth="95%" spacing={2} sx={{ margin: "auto" }}>
          <Navigation pageName="Abenteuer" />
          <Grid container item md={10} xs={12} marginBottom={50}>
            <Grid item xs={12}>
              {(isLoading || isFetching) && <CircularProgress />}
              {!(isLoading || isFetching) && data && (
                <DataTable
                  search={false}
                  delete={user?.admin || user?.roles.includes(Roles.EXPERIENCE_DELETE) || false}
                  copy={user?.admin || user?.roles.includes(Roles.EXPERIENCE_COPY) || false}
                  edit={user?.admin || user?.roles.includes(Roles.EXPERIENCE_EDIT) || false}
                  add={user?.admin || user?.roles.includes(Roles.EXPERIENCE_CREATE) || false}
                  data={mapToDataField(data ?? [])}
                  headCells={headCells}
                  copyElement={copyElement}
                  navigationHelpers={{
                    editElement: editElement,
                    current: "experiences",
                    destination: "experienceForm",
                  }}
                  tableHelpers={{
                    comparatorField: "id",
                    rowRenderer: rowRenderer,
                  }}
                  icons={true}
                  paging={{
                    totalCount: data.length ?? 0,
                    currentPage: page,
                    currentPageSize: pageSize,
                    setCurrentPage: setPage,
                    setCurrentPageSize: setPageSize,
                    useSlicer: false, //we don't need slicing, since data[] always contains only the current page data
                  }}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <ExperienceByCodeForm />
            </Grid>
          </Grid>
        </Grid>
        <Footer />
      </CrLoadingOverlay>
    </>
  )
}
