import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useAppDispatch, useAppSelector } from "../../../../app/hooks"
import { RootState } from "../../../../app/store"
import { CityRiddlerFormInputLabel } from "../../../../app/themes"
import { ExperienceFormText } from "../../../../i18"
import { IntroTextWithOrderIndex } from "../../../../app/data"
import {
  SelectIntroTextInputFieldsData,
  SelectIsError,
  setDescriptionLong,
  setDescriptionShort,
  setDisplayName,
  setIntroHeaders,
  setIntroTexts,
} from "../../experienceFormSlice"
import _ from "lodash"
import { IntroDynamicInputField } from "./introDynamicInputField"
import { InternationalText, Language } from "../../../api/cityriddlerApi"
import { TextInput } from "../../../../app/utils/components/input/textInput"

interface IntroTextInputFieldsProperties {
  language: Language
}

export const IntroTextInputFields = (props: IntroTextInputFieldsProperties) => {
  const { t } = useTranslation()
  const dispatch = useAppDispatch()
  const { language } = props
  const isFormError = useAppSelector(SelectIsError)
  const { introHeaders, introTexts, displayName, descriptionShort, descriptionLong } = useAppSelector(
    (state: RootState) => SelectIntroTextInputFieldsData(state, language),
  )

  const handleIntroHeaderChange = (data: IntroTextWithOrderIndex[], input?: { text: string; index: number }) => {
    console.log("handleIntroHeaderChange")
    let headers = _.cloneDeep(data)
    if (!input) {
      headers.forEach((header, index) => (header.orderIndex = index))
    } else {
      headers.forEach((header, index) => {
        header.orderIndex = index
        if (index === input.index && header.language.id === language.id) {
          header.text = input.text
        }
      })
    }
    dispatch(setIntroHeaders(headers))
  }

  const handleIntroTextChange = (data: IntroTextWithOrderIndex[], input?: { text: string; index: number }) => {
    console.log("handleIntroTextChange")
    let texts = _.cloneDeep(data)
    if (!input) {
      texts.forEach((text, index) => (text.orderIndex = index))
    } else {
      texts.forEach((text, index) => {
        text.orderIndex = index
        if (index === input.index && text.language.id === language.id) {
          text.text = input.text
        }
      })
    }
    console.log(texts)
    dispatch(setIntroTexts(texts))
  }

  const onDisplayNameChange = (value: string) => {
    console.log("onDisplayNameChange")
    if (displayName) {
      let updatedDisplayName = _.cloneDeep(displayName)
      updatedDisplayName.text = value
      dispatch(setDisplayName(updatedDisplayName))
    } else {
      let newDisplayName = {
        text: value,
        language: language,
      } as InternationalText
      dispatch(setDisplayName(newDisplayName))
    }
  }

  const onDescriptionShortChange = (value: string) => {
    console.log("onDescriptionShortChange")
    if (descriptionShort) {
      let updatedDescriptionShort = _.cloneDeep(descriptionShort)
      updatedDescriptionShort.text = value
      dispatch(setDescriptionShort(updatedDescriptionShort))
    } else {
      let newDescriptionShort = {
        text: value,
        language: language,
      } as InternationalText
      dispatch(setDescriptionShort(newDescriptionShort))
    }
  }

  const onDescriptionLongChange = (value: string) => {
    console.log("onDescriptionLongChange")
    if (descriptionLong) {
      let updatedDescriptionLong = _.cloneDeep(descriptionLong)
      updatedDescriptionLong.text = value
      dispatch(setDescriptionLong(updatedDescriptionLong))
    } else {
      let newDescriptionLong = {
        text: value,
        language: language,
      } as InternationalText
      dispatch(setDescriptionLong(newDescriptionLong))
    }
  }

  const getLanguageName = (language: Language) => {
    switch (language.id) {
      case 1:
        return t(ExperienceFormText.german)
      case 2:
        return t(ExperienceFormText.english)
    }
  }

  return (
    <Grid container item xs={12} spacing={2}>
      <Grid item xs={12}>
        <CityRiddlerFormInputLabel htmlFor="intro" required error={isFormError}>
          {getLanguageName(language) + `:`}
        </CityRiddlerFormInputLabel>
      </Grid>
      <Grid container item id="intro" xs={12} spacing={2}>
        <Grid container item xs={12} spacing={2}>
          <IntroDynamicInputField
            name="introHeader"
            label={t(ExperienceFormText.introHeader)}
            handleChange={handleIntroHeaderChange}
            data={introHeaders}
            error={isFormError}
            maxInputCount={4}
            language={language}
            infoText={t(ExperienceFormText.introHeaderInfo)}
            required={false}
            multiline={false}
          />
        </Grid>

        <Grid container item xs={12} spacing={2}>
          <IntroDynamicInputField
            name="introText"
            label={t(ExperienceFormText.introText)}
            handleChange={handleIntroTextChange}
            data={introTexts}
            error={isFormError}
            maxInputCount={4}
            language={language}
            infoText={t(ExperienceFormText.introTextInfo)}
            required={false}
            multiline={true}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-displayName`}
            error={isFormError && !displayName?.text}
            required={false}
            value={displayName?.text ? displayName.text : ""}
            multiline={true}
            handleChange={onDisplayNameChange}
            label={`${t(ExperienceFormText.displayName)}:`}
            infoText={"Info Text"}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-descriptionShort`}
            error={isFormError && !descriptionShort?.text}
            required={false}
            value={descriptionShort?.text ? descriptionShort.text : ""}
            multiline={true}
            handleChange={onDescriptionShortChange}
            label={`${t(ExperienceFormText.descriptionShort)}:`}
            infoText={"Info Text"}
          />
        </Grid>

        <Grid item md={6} xs={12}>
          <TextInput
            id={`${language.id}-descriptionLong`}
            error={isFormError && !descriptionLong?.text}
            required={false}
            value={descriptionLong?.text ? descriptionLong.text : ""}
            multiline={true}
            handleChange={onDescriptionLongChange}
            label={`${t(ExperienceFormText.descriptionLong)}:`}
            infoText={"Info Text"}
          />
        </Grid>
      </Grid>
    </Grid>
  )
}
