import { CircularProgress, Grid, Typography } from "@mui/material"
import { GoogleMap, InfoWindowF, MarkerF, useJsApiLoader } from "@react-google-maps/api"
import React, { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import { Address, Riddle, useGetAllRiddlesPagedQuery, useGetOwnedRiddlesOfUserQuery } from "../api/cityriddlerApi"
import { extractText } from "../../app/utils/components/text/internationalTextService"
import { renderToString } from "react-dom/server"
import { RiddleFilter } from "../../app/utils/components/riddleFilter/riddleFilter"
import { CityRiddlerButton } from "../../app/themes/cityRiddlerButton"
import { mapRiddleToForm } from "../riddleForm/riddleMapper"
import { useNavigate } from "react-router-dom"
import { AuthContext } from "../../firebase/authProvider"

const containerStyle = {
  width: "100%",
  height: "500px",
  referrerPolicy: "no-referrer-when-downgrade",
}

const options = {
  fullscreenControl: false,
  mapTypeControl: false,
  zoomControlOptions: {
    position: 19,
  },
  streetViewControl: false,
}

const libraries: (
  | "core"
  | "maps"
  | "places"
  | "geocoding"
  | "routes"
  | "marker"
  | "geometry"
  | "elevation"
  | "streetView"
  | "journeySharing"
  | "drawing"
  | "visualization"
)[] = ["core", "maps", "places", "routes", "marker", "geometry"]

export const MapDisplay = () => {
  const navigate = useNavigate()
  const { t } = useTranslation()
  const { user } = useContext(AuthContext)
  const { data, isLoading } = useGetOwnedRiddlesOfUserQuery({ userId: user?.uid! })
  const [map, setMap] = useState<google.maps.Map | null>(null)
  const [filteredData, setFilteredData] = useState<Riddle[]>([])
  const [selectedRiddle, setSelectedRiddle] = useState<Riddle | null>(null)

  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY ? process.env.REACT_APP_GOOGLE_API_KEY : "",
    libraries: libraries,
  })

  useEffect(() => {
    if (map) fitMapToBounds(map)
  }, [data])

  const fitMapToBounds = (map: google.maps.Map) => {
    const dataToFit = filteredData ?? data ?? []
    const bounds = new window.google.maps.LatLngBounds()
    dataToFit.forEach((stop) => {
      bounds.extend(mapToLatLng(stop))
    })
    map.fitBounds(bounds)
  }

  const onLoad = React.useCallback((map: google.maps.Map) => {
    setMap(map)
    fitMapToBounds(map)
  }, [])

  const onUnmount = React.useCallback((map: google.maps.Map) => {
    setMap(null)
  }, [])

  const mapToLatLng = (riddle: Riddle): google.maps.LatLng => {
    const gpsData = riddle.riddleMetadata.location.gpsData
    return new google.maps.LatLng(gpsData.latitude, gpsData.longitude)
  }

  const getAddress = (address: Address | undefined): string => {
    if (!address) return "unknown"
    return `${address.street} ${address.streetNumber}, ${address.zipCode} ${address.city}`
  }

  const handleFilterChange = (filtered: Riddle[]) => {
    setFilteredData(filtered)
    if (map) fitMapToBounds(map)
  }

  const editRiddle = () => {
    if (selectedRiddle) {
      navigate(`/riddleForm`, {
        state: mapRiddleToForm(selectedRiddle, true),
      })
    }
  }

  const infoWindowContent =
    selectedRiddle &&
    renderToString(
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="subtitle1">
            <b>{selectedRiddle.id + ": " + extractText(selectedRiddle.title).text}</b>
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Typography variant="caption">{extractText(selectedRiddle.description).text}</Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="caption">
            <b>Addresse: </b>
          </Typography>
        </Grid>
        <Grid item xs={9}>
          <Typography variant="caption">{getAddress(selectedRiddle.riddleMetadata.location.address)}</Typography>
        </Grid>
        <Grid item xs={12}></Grid>
      </Grid>,
    )

  return (
    <Grid container item spacing={1} alignContent="center">
      <Grid item xs={12}>
        <RiddleFilter riddles={data} handleFilterChange={handleFilterChange} />
      </Grid>
      <Grid item xs={12}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={containerStyle}
            zoom={17}
            onLoad={onLoad}
            onUnmount={onUnmount}
            options={options}>
            {filteredData?.map((riddle, index) => (
              <MarkerF
                key={riddle.id}
                position={mapToLatLng(riddle)}
                label={"" + riddle.id}
                onClick={() => setSelectedRiddle(riddle)}
              />
            ))}
            {selectedRiddle && (
              <InfoWindowF
                position={mapToLatLng(selectedRiddle)}
                options={{
                  content: infoWindowContent,
                  maxWidth: 300,
                }}
                onCloseClick={() => {
                  setSelectedRiddle(null)
                }}>
                <div>
                  <CityRiddlerButton variant="contained" onClick={editRiddle}>
                    Bearbeiten
                  </CityRiddlerButton>
                </div>
              </InfoWindowF>
            )}
          </GoogleMap>
        ) : (
          <CircularProgress />
        )}
      </Grid>
    </Grid>
  )
}

export default MapDisplay
