export enum Roles {
  RIDDLE_CREATE = "RIDDLE_CREATE",
  RIDDLE_EDIT = "RIDDLE_EDIT",
  RIDDLE_COPY = "RIDDLE_COPY",
  RIDDLE_DELETE = "RIDDLE_DELETE",

  EXPERIENCE_CREATE = "EXPERIENCE_CREATE",
  EXPERIENCE_READ = "EXPERIENCE_READ",
  EXPERIENCE_EDIT = "EXPERIENCE_EDIT",
  EXPERIENCE_COPY = "EXPERIENCE_COPY",
  EXPERIENCE_DELETE = "EXPERIENCE_DELETE",

  RIDDLE_MAP = "RIDDLE_MAP",
}
